import Banner from "../components/gala/banner";
import CardSponsorship from "../components/gala/cardSponsorship";

import ImageAraratMap from "../assets/images/gala-2023/ararat-region-map.jpg";
import ImageBrochure1 from "../assets/images/gala-2023/brochure-invitation.jpg";
import ImageBrochure2 from "../assets/images/gala-2023/brochure-clinic.jpg";
import ImageBrochure3 from "../assets/images/gala-2023/brochure-sponsorship-1.jpg";
import ImageBrochure4 from "../assets/images/gala-2023/brochure-sponsorship-2.jpg";
import ImageMazmanian from "../assets/images/gala-2023/dr-mazmanian.jpg";

export default function GalaEvent() {
  const sponsorships = [
    {
      title: "Gateway To Hope",
      donation: "$100,000",
      honor:
        "Building of the Physical Therapy & Rehabilitation Floor and its Naming Rights",
      benefits: [
        "Premiere Seating for 20 Humanitarians",
        "Full Page Ad in the Event Program",
        "Company Name/Logo Placement on All Marketing Materials (On-Screen, Step-and-Repeat, Website, Social Media)",
      ],
    },
    {
      title: "Gateway To Mindfulness",
      donation: "$50,000",
      honor: "Addition of the Tranquility Courtyard and its Naming Rights",
      benefits: [
        "Premiere Seating for 10 Humanitarians",
        "Full Page Ad in the Event Program",
        "Company Name/Logo Placement on All Marketing Materials (On-Screen, Step-and-Repeat, Website, Social Media)",
      ],
    },
    {
      title: "Gateway To Wisdom",
      donation: "$25,000",
      honor: "Building of the Conference Center/Library and its Naming Rights",
      benefits: [
        "Premiere Seating for 10 Humanitarians",
        "Full Page Ad in the Event Program",
        "Company Name/Logo Placement on All Marketing Materials (On-Screen, Step-and-Repeat, Website, Social Media)",
      ],
    },
    {
      title: "Gateway To Lifelong Wellness",
      donation: "$10,000",
      honor: "Building of One Therapy/Exam Room and its Naming Rights",
      benefits: [
        "Premiere Seating for 6 Humanitarians",
        "Half Page Ad in the Event Program",
        "Company Name/Logo Placement on All Marketing Materials (On-Screen, Step-and-Repeat, Website, Social Media)",
        "10 Rooms Available",
      ],
    },
    {
      title: "Gateway To Strength",
      donation: "$5,000",
      benefits: [
        "Premiere Seating for 4 Humanitarians",
        "Quarter Page Ad in the Event Program",
        "Company Name/Logo Placement on All Marketing Materials (On-Screen, Step-and-Repeat, Website, Social Media)",
      ],
    },
    {
      title: "Gateway To Compassion",
      donation: "$2,500",
      benefits: [
        "Premiere Seating for 2 Humanitarians",
        "Honorable Mention in the Event Program and Marketing Materials",
      ],
    },
  ];

  const brochureImages = [
    {
      image: ImageBrochure1,
      alt: "Gateway Industry 10th Anniversary Gala Invitation",
    },
    {
      image: ImageBrochure2,
      alt: "Gateway Health Clinic & Rehabilitation Center Information",
    },
    {
      image: ImageBrochure3,
      alt: "10th Anniversary Gala Sponsorship Opportunities",
    },
    {
      image: ImageBrochure4,
      alt: "10th Anniversary Gala Sponsorship Opportunities (continued)",
    },
  ];

  return (
    <>
      <div className="container-fluid mx-auto max-lg:px-4 pt-2 bg-white">
        <Banner />
      </div>
      <div className="container-fluid bg-white">
        <div className="container mx-auto px-4 pt-2 pb-4 md:py-8">
          <h2 className="text-lg text-center leading-relaxed max-lg:pt-2 lg:mx-32">
            You are cordially invited to join us for the highly
            anticipated&nbsp;
            <b>
              Gateway Industry 10<sup>th</sup> Anniversary Gala,&nbsp;
              <i className="text-main">
                A Decade of Empowerment: Transforming Lives and Advancing
                Futures,
              </i>
            </b>
            &nbsp;on Sunday, October 29, 2023 at 5:00pm. The event will take
            place at the Grand Venue, 3070 Los Feliz Blvd, Los Angeles,
            California, 90039.
          </h2>
          <div className="flex max-md:flex-col justify-center max-md:items-center max-md:space-y-4 md:space-x-6 lg:mx-40 mt-4 lg:mt-6">
            <a
              href="https://gatewayindustrygala.rsvpify.com/"
              className="flex items-center justify-center w-full py-4 text-lg md:text-xl font-semibold text-center text-white rounded-lg hover:scale-[1.04] focus:scale-[1.04] active:scale-100 bg-main"
            >
              Purchase Tickets - $450
            </a>
            <a
              href="https://www.paypal.com/donate/?hosted_button_id=FNWZ4NNKYXCFN"
              className="flex items-center justify-center w-full py-4 text-lg md:text-xl font-semibold text-center text-white rounded-lg hover:scale-[1.04] focus:scale-[1.04] active:scale-100 bg-gray-800"
            >
              Donate Without Attending
            </a>
          </div>
        </div>
      </div>
      <div className="container mx-auto max-lg:px-4 pb-4">
        <h3 className="text-2xl md:text-3xl my-4 md:my-8 font-bold text-center text-gray-700">
          Sponsorship Opportunities
        </h3>
        <div className="lg:grid lg:grid-cols-3 lg:gap-4">
          {sponsorships.map((item, index) => {
            return (
              <CardSponsorship
                key={index}
                data={item}
                cardCSS={index < sponsorships.length - 1 ? "max-lg:mb-4" : ""}
              />
            );
          })}
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="container mx-auto px-4 lg:px-0 py-4">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div>
              <p className="mb-4">
                We are delighted to announce that&nbsp;
                <span className="font-semibold text-main">
                  Dr. Sarkis Mazmanian, Ph.D.
                </span>
                , a distinguished scientist and professor at California
                Institute of Technology will be our <b>keynote speaker</b>.
              </p>
              <p className="mb-4">
                Dr. Mazmanian is the current{" "}
                <i>Luis B. and Nelly Soux Professor of Microbiology</i>{" "}
                and&nbsp;
                <i>
                  Heritage Medical Research Institute Principal Investigator
                </i>{" "}
                at CalTech. We are honored and proud to have such a renowned
                Armenian researcher join us on this special occasion.
              </p>
              <img
                src={ImageMazmanian}
                className="rounded-lg object-contain lg:w-[100%] lg:h-auto mb-4"
                alt="Keynote speaker Dr. Sarkis Mazmanian, Ph.D., professor at California Institute of Technology"
              />
              <p className="mb-4">
                As{" "}
                <span className="font-semibold text-main">
                  Gateway Industry
                </span>{" "}
                marks a&nbsp;
                <span className="font-semibold text-main">
                  decade of commitment
                </span>{" "}
                to our homeland, we embark on a new journey of building a
                first-of-its-kind non-profit primary care clinic and
                rehabilitation center in Armenia's Ararat province.
              </p>
              <p className="max-lg:mb-4">
                This ambitious mission marks a historic milestone as it will be
                the first diaspora-sanctioned and operated non-profit primary
                health clinic dedicated to providing comprehensive and
                compassionate primary medical services, in addition to
                specialized physical rehabilitation and psychological programs.
              </p>
            </div>
            <div>
              <p className="mb-4">
                <span className="font-semibold text-main">
                  The Gateway Health
                </span>{" "}
                clinic will provide free-of-charge services to our veterans,
                recognizing their invaluable service, dedication, and commitment
                to our homeland. This will in turn, help our veterans regain
                their physical and mental health to re-integrate and become
                important assets in Armenia's society.
              </p>
              <p className="lg:mb-2">
                In addition, we are equally committed to serving the broader
                community of Ararat, ensuring access to the highest quality
                primary healthcare for all. Our dedicated team of local doctors,
                nurses and administrative staff will receive invaluable support
                from our rotating panel of visiting Diasporan clinicians and
                specialists, facilitating the transfer of skill and transfer of
                knowledge of state-of-the-art Western standards of care.
                Together, we aim to set a new benchmark of healthcare services
                in Armenia.
              </p>
              <img
                src={ImageAraratMap}
                className="rounded-lg object-contain lg:h-80 mb-2 mx-auto"
                alt="Gateway's primary care clinic and rehabilitation center in Armenia's Ararat region"
              />
              <p className="mb-2">
                With the establishment of this first of its kind non-profit
                comprehensive primary clinic, our aim is to accomplish the
                following:
              </p>
              <ol className="list-decimal mt-2 ml-4">
                <li className="mb-2">
                  <span className="font-semibold text-main">
                    Empower and strengthen
                  </span>{" "}
                  our veterans and the community at large with the
                  transformative power of a regional healthcare infrastructure.
                </li>
                <li className="mb-2">
                  Contribute to the overall&nbsp;
                  <span className="font-semibold text-main">
                    advancement and standardization
                  </span>{" "}
                  of the healthcare infrastructure locally and nationally.
                </li>
                <li className="mb-2">
                  Create a{" "}
                  <span className="font-semibold text-main">
                    working bridge
                  </span>{" "}
                  between local and Diasporan clinicians and professionals to
                  foster the transfer of skill and knowledge.
                </li>
                <li className="mb-2">
                  As a beacon of hope, the clinic will&nbsp;
                  <span className="font-semibold text-main">
                    elevate the community's health
                  </span>{" "}
                  and overall satisfaction of the Ararat province at large.
                </li>
                <li className="mb-0">
                  Healthier citizens with proper access to healthcare and
                  preventative care will be more prepared during national crises
                  and better equipped to contribute to overall&nbsp;
                  <span className="font-semibold text-main">
                    national security of Armenia
                  </span>
                  .
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto max-lg:px-4 py-4">
        <p className="mb-4 p-4 md:p-6 bg-main text-white text-center font-semibold rounded-md">
          The event promises to be an unforgettable evening of inspiration,
          community and the fulfillment of the soul through transformative
          philanthropic work for our homeland! Please mark your calendars for
          this highly anticipated event. We look forward to seeing you!
        </p>
        <div className="lg:grid lg:grid-cols-4 lg:gap-4 max-lg:space-y-4">
          {brochureImages.map((item, index) => {
            return (
              <a key={index} href={item.image} className="block">
                <img
                  src={item.image}
                  className="rounded-md"
                  title={item.alt}
                  alt={item.alt}
                ></img>
              </a>
            );
          })}
        </div>
        <p className="lg:text-center text-sm font-bold mt-4 max-lg:mb-4">
          Gateway Industry is a 501(c)(3) non-profit organization, EIN
          47-3137456, and your contribution is tax-deductible.
        </p>
        <p className="lg:text-center text-sm font-semibold">
          Please email us at&nbsp;
          <a href="mailto:gatewayassn@gmail.com" className="text-blue-900">
            gatewayassn@gmail.com
          </a>
          &nbsp;with any questions regarding the event.
        </p>
      </div>
    </>
  );
}
