import galaHero from "../assets/images/gala-2024/Poster.png";
import CardSponsorship from "../components/gala/cardSponsorship";
import sponsorshipImg1 from "../assets/images/gala-2024/sponsorship/1.png";
import sponsorshipImg2 from "../assets/images/gala-2024/sponsorship/2.png";
import sponsorshipImg3 from "../assets/images/gala-2024/sponsorship/3.png";
import sponsorshipImg4 from "../assets/images/gala-2024/sponsorship/4.png";
import sponsorshipImg5 from "../assets/images/gala-2024/sponsorship/5.png";
import sponsorshipImg6 from "../assets/images/gala-2024/sponsorship/6.png";
import sponsorshipImg7 from "../assets/images/gala-2024/sponsorship/7.png";

export default function Gala2024() {
  const sponsorships = [
    {
      image: sponsorshipImg1,
      title: "General Admission",
      donation: "$250",
      sponsorMessage: "I pledge to sponsor the to General Admission for",
      // honor:
      //   "Building of the Physical Therapy & Rehabilitation Floor and its Naming Rights",
      benefits: ["Standard seats with vibrant concert energy"],
    },
    {
      image: sponsorshipImg2,
      title: "Premier Seating",
      donation: "$350",
      sponsorMessage: "I pledge to sponsor the to Premier Seating for",
      // honor:
      //   "Building of the Physical Therapy & Rehabilitation Floor and its Naming Rights",
      benefits: ["Excellent seats for an elevated concert experience"],
    },
    {
      image: sponsorshipImg3,
      title: "VIP Seating",
      donation: "$450",
      sponsorMessage: "I pledge to sponsor the to VIP Seating for",
      // honor:
      //   "Building of the Physical Therapy & Rehabilitation Floor and its Naming Rights",
      benefits: ["Excellent seats for an elevated concert experience"],
    },
    {
      image: sponsorshipImg4,
      title: "Gateway To Compassion",
      donation: "$2,500",
      sponsorMessage: "I pledge to sponsor the to Gateway to Compassion for",
      // honor:
      //   "Building of the Physical Therapy & Rehabilitation Floor and its Naming Rights",
      benefits: [
        "Premier Seating for 4",
        "Marketing Opportunities",
        "Autographed Item",
      ],
    },
    {
      image: sponsorshipImg5,
      title: "Gateway To Strength",
      donation: "$5000",
      sponsorMessage: "I pledge to sponsor the to Gateway to Compassion for",
      // honor: "Addition of the Tranquility Courtyard and its Naming Rights",
      benefits: [
        "VIP Seating for 6",
        "Marketing Opportunities",
        "Autographed Item",
      ],
    },
    {
      image: sponsorshipImg6,
      title: "Gateway To Lifelong Wellness",
      donation: "$10,000",
      sponsorMessage: "I pledge to sponsor the to Gateway to Compassion for",
      // honor: "Building of the Conference Center/Library and its Naming Rights",
      benefits: [
        "VIP Seating for 12",
        "Marketing Opportunities",
        "Autographed Item",
      ],
    },
    {
      image: sponsorshipImg7,
      title: "Gateway To Wisdom",
      donation: "$25,000",
      sponsorMessage: "I pledge to sponsor the to Gateway to Compassion for",
      // honor: "Building of One Therapy/Exam Room and its Naming Rights",
      benefits: [
        "VIP Seating for 12 ",
        "Marketing Opportunities",
        "Verbal Recognition I Autographed Item",
        "1Meet-and-greet with performers",
      ],
    },
  ];

  return (
    <>
      <div className="bg-white">
        <div className="container mx-auto px-4 space-y-10">
          <div>
            <img src={galaHero} alt="gala hero" />
          </div>
          <div className="pb-10">
            <h2 className="text-lg text-center leading-relaxed max-lg:pt-2 lg:mx-32">
              You are cordially invited to join us for the highly anticipated
              benefit concert for{" "}
              <strong className="text-main">Ararat Health Clinic</strong>, on{" "}
              <strong>Sunday, November 17, 2024, at 5:00 PM</strong>. The event
              will take place at the{" "}
              <strong>
                Grand Venue, 3070 Los Feliz Blvd, Los Angeles, California, 90039
              </strong>
              .
            </h2>
            <div className="flex max-md:flex-col justify-center max-md:items-center max-md:space-y-4 md:space-x-6 lg:mx-40 mt-4 lg:mt-6">
              <a
                href="https://gatewayindustryconcert.rsvpify.com/?securityToken=1cDGWs7ZrD5Bxph5mQcg9yEXPslqw8cf"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-full py-4 text-lg md:text-xl font-semibold text-center text-white rounded-lg hover:scale-[1.04] focus:scale-[1.04] active:scale-100 bg-main"
              >
                Reserve Seats and Sponsorship
              </a>
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=FNWZ4NNKYXCFN"
                className="flex items-center justify-center w-full py-4 text-lg md:text-xl font-semibold text-center text-white rounded-lg hover:scale-[1.04] focus:scale-[1.04] active:scale-100 bg-gray-800"
              >
                Donate Without Attending
              </a>
            </div>
            <div>
              <p className="lg:text-center text-sm font-bold mt-4 max-lg:mb-4">
                Gateway Industry is a 501(c)(3) non-profit organization, EIN
                47-3137456, and your contribution is tax-deductible.
              </p>
              <p className="lg:text-center text-sm font-semibold">
                Please email us at&nbsp;
                <a
                  href="mailto:gatewayassn@gmail.com"
                  className="text-blue-900"
                >
                  gatewayassn@gmail.com
                </a>
                &nbsp;with any questions regarding the event.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container py-10 mx-auto px-4">
        <div className="space-y-10">
          <h3 className="text-2xl md:text-3xl font-bold text-center text-gray-700">
            Sponsorship Opportunities
          </h3>
          <div className="grid gap-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {sponsorships.map((item, index) => {
              return (
                <CardSponsorship
                  showImage={true}
                  key={index}
                  data={item}
                  cardCSS={index < sponsorships.length - 1 ? "max-lg:mb-4" : ""}
                />
              );
            })}
          </div>
          <a
            onClick={(e) => e.stopPropagation()}
            href="https://haykpetrosyangateway.rsvpify.com/?securityToken=TYhUki7gBel9zyo3c6oDCYB27wW3jR7J"
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold block w-fit px-10 mx-auto mt-5 bg-main text-white text-center py-3"
          >
            Reservation
          </a>
        </div>
      </div> */}
    </>
  );
}
