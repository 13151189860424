import React from "react";
import { Route, Routes } from "react-router-dom";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./index.css";

import Contact from "./Pages/contact";
import Footer from "./Pages/layouts/footer";
import GalaEvent from "./Pages/galaEvent";
import Gallery from "./Pages/gallery";
import GatewayTechnologies from "./Pages/gatewayTechnologies";
import GatewayToEducationNewPath from "./Pages/gatewayToEducationNewPath";
import GatewayToHealth from "./Pages/gatewayToHealth";
import Header from "./Pages/layouts/header";
import Home from "./Pages/home";
import OurMission from "./Pages/ourMission";
import OurStory from "./Pages/ourStory";
import OurTeam from "./Pages/ourTeam";
import Reports from "./Pages/reports";
import TeacherRetrainingProject from "./Pages/teacherRetrainingProject";
import TumoKidsTransportation from "./Pages/tumoKidsTransportation";
import GatewayClinic from "./Pages/gatewayClinic";
import Gala2024 from "./Pages/gala2024";

export default function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us/our-mission" element={<OurMission />} />
        <Route path="/about-us/our-story" element={<OurStory />} />
        <Route path="/about-us/our-team" element={<OurTeam />} />
        <Route path="/about-us/reports" element={<Reports />} />
        <Route path="/gala" element={<GalaEvent />} />
        <Route path="/benefit-concert" element={<Gala2024 />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/our-work/gateway-to-education/new-path"
          element={<GatewayToEducationNewPath />}
        />
        <Route
          path="/our-work/gateway-to-education/teacher-retraining-project"
          element={<TeacherRetrainingProject />}
        />
        <Route
          path="/our-work/gateway-to-education/tumo-kids-transportation"
          element={<TumoKidsTransportation />}
        />
        <Route
          path="/our-work/gateway-to-health/veterans-rehab-project"
          element={<GatewayToHealth />}
        />
        <Route
          path="/our-work/gateway-to-health/gateway-clinic"
          element={<GatewayClinic />}
        />
        <Route
          path="/our-work/gateway-to-science-and-technology"
          element={<GatewayTechnologies />}
        />
      </Routes>
      <Footer />
    </>
  );
}
